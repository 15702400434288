.product-card {
  background-color: #fff;
  border-radius: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin: 1rem;
  text-align: center;
  width: 28%;
  max-width: 500px;
}

.product-card-content {
  border-radius: 2rem 2rem 0 0;
  padding: 1rem;
  height: 250px;
  position: relative;
}

.product-card-header {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.product-card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.product-card-price {
  color: #333;
  font-size: 1.5rem;
}

.product-card-value {
  margin-bottom: 1rem;
}

.product-card-value-text {
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-blue); /* Adjust for your desired value text color */
}

.product-card-details-wrapper {
  position: relative;
  padding: 1rem;
}

.product-card-details-description {
  font-size: 1rem;
  margin-bottom: 1rem;
  font-style: italic;
}

.product-card-details {
  list-style: none;
  padding: 0;
  margin: 0;
}

.product-card-details li {
  margin-bottom: 0.2rem;
  font-size: 0.9rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.product-card-disclaimer {
  font-size: 0.8rem;
  color: #888;
  margin-bottom: 1rem;
} 

.product-card-button-wrapper {
  width: 100%;
  position: absolute;
  display: flex;
  left: 0;
  bottom: 0;
  justify-content: center;
  padding: 1rem;
}

.product-card-button {
  background-color: var(--color-blue);
  color: var(--color-secondary); 
  padding: 0.6rem 0.8rem;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.2s ease-in-out;
}

.product-card-button:hover {
  color: var(--color-light-red);
  font-weight: bold;
}

/* MEDIA QUERIES (MEDIUM AND SMALL) */
@media screen and (max-width: 1024px) {
  .product-card {
    width: 45%;
  }
}

@media screen and (max-width: 800px) {
  .product-card {
    width: 95%;
  }
}