
.serviceCard {
    display: block;
    position: relative;
    padding: 1rem;
}

.serviceCard h5 {
    color: var(--color-blue);
    margin-bottom: 1rem;
    font-weight: bold;
}

.serviceCard p {
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
}

.serviceCard hr {
    background-color: var(--color-blue);
    height: 0.1rem;
}

.service-card-action {
    margin-top: 2rem;
    display: block;
}

