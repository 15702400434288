.round-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.round-checkbox input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    cursor: pointer;
}

.round-checkbox .checkbox-label {
    margin-left: 1px;
    line-height: 3rem;
}

/* Custom Checkbox Design */
.round-checkbox .checkbox-label:before {
    content: "";
    display: inline-block;
    margin-right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: 2px solid var(--color-primary);
    background-color: white;
    transition: border-color 0.2s ease-in-out;
}

.round-checkbox input[type="checkbox"] {
    display: none;
}

.round-checkbox input[type="checkbox"]:checked+.checkbox-label:before {
    border-color: #007bff;
    background-color: #007bff;
}