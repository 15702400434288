.darkBG {
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 8;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
}


.modal-centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 60%
}

.modal-centered .modal {
    width: auto;
    max-height: 100vh;
    height: auto;
    background: var(--color-secondary);
    color: var(--color-primary);
    border-radius: 30px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    padding: 5rem 6rem 2rem 6rem;
}

.modal-centered .modal .close {
    text-align: right;
    position: absolute;
    right: 1rem;
    top: 1rem;
    font-size: 3rem;
    color: var(--color-blue);
    cursor: pointer;
}

.modal .content {
    max-height: 75vh;
}

.modal .content ol {
    list-style-type: decimal;
    list-style-position: outside;
    padding-left: 1em;
}
.modal .content ol li {
    list-style: decimal;
}

.modal .content.scroll-y {
    overflow-y: scroll;
}

@media screen and (max-width: 1024px) {
    .modal-centered {
        width: 80%
    }
    
    .modal-centered .modal {
        padding: 5rem 2rem 2rem 2rem;
    }
}