.page-header.post-header {
    text-align: center;
    padding: 3rem 6rem;
    background-color: var(--color-light-gray);
}

.page-content .post-info .author {
    text-align: right;
    margin: auto;
    font-size: 0.8rem;
    font-weight: bold;
}

.page-content .post-info .credited-image {
    max-width: 400px;
    margin: auto;
    padding: auto;
}

.page-content .post-info,.page-content .post-text {
    padding: 1rem 0rem;
    overflow-x: hidden;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .cards-container .blogCard {
        max-width: 100%;
        flex: 1 0 100%; /* Take up 1/2 of the row (2 items per row), minus some margin */
    }
}