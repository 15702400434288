.footer-container {
    width: 100%;
    padding: 2rem 3rem;
    background-color: var(--color-blue);
    color: var(--color-secondary);
    position: relative;
}

.footer-row {
    display: flex;
    flex-direction: row;
    gap: 6rem;
}

.footer-row.copy {
    padding-top: 2rem;
    justify-content: space-between;
}

.footer-row.copy a {
    color: var(--color-secondary);
}

.footer-row.copy a:hover {
    color: var(--color-light-blue);
}

.footer-logo {
    height: 8rem;
    margin-left: 4rem;
    object-fit: contain;
}

.wa-icon-footer {
    position: absolute;
    top: -40px;
    right: 10%;
}

@media screen and (max-width: 1024px) {
    .footer-row {
        flex-direction: column;
    }

    .footer-logo {
        height: 9rem;
        margin-left: 0;
        align-self: baseline;
    }
}
