.credited-image {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.credited-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 1rem;
}

.credited-image span {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: var(--color-primary-alpha-70);
    color: var(--color-secondary);
    padding: 0.4rem 0.8rem;
    border-radius: 1rem;
    font-size: 0.6rem;
}

@media screen and (max-width: 1024px) {
    .credited-image {
        padding: 0 8%;
    }

    .credited-image span {
        right: 8%;
    }
}