.button-seemore {
    color: var(--color-blue);
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.button-seemore:hover, .button-seemore:hover span {
    font-weight: bold;
}

.button-seemore span {
    font-size: 1.3rem;
    color: var(--color-blue);
    margin-top: 0.2rem;
}