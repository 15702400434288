.contact-form {
    display: grid;
    grid-template-columns: 30% 50%;
    grid-template-rows: 100%;
    justify-content: center;
    align-items: start;
    text-align: left;
    gap: 5rem;
    
}
.contact-form img {
    width: 100%;
}

.contact-form div {
    display: block;
}

.contact-form input, .contact-form textarea {
    margin-bottom: 0.1rem;
}

.contact-form .error {
    color: brown;
}

.contact-form .success {
    color: green;
}

.action-buttons {
    display: flex !important;
    justify-content: center;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
}

@media screen and (max-width: 1024px) {
    .contact-form {
        grid-template-columns: 100%;
        grid-template-rows: auto 60%;
        justify-content: center;
        align-items: start;
        gap: 1rem;
    }

    .contact-form img {
        width: 60%;
        margin: auto;
    }
}
