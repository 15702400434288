.product-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 800px) {
  .product-cards-container {
    flex-direction: column;
    align-items: center;
  }
}
