.page-header.blog-header {
    text-align: center;
    padding: 3rem 6rem;
    background-color: var(--color-light-gray);
}

.page-content.blog-page {
    padding: 4rem 4rem;
    background-color: var(--color-secondary);
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 1rem 8rem;
}

.cards-container .blogCard {
    flex: 1 0 calc(33.33% - 40px); /* Take up 1/3 of the row (3 items per row), minus some margin */
    margin: 20px; /* Optional: add some margin around items */
    max-width: calc(33.33% - 40px); /* Optional: set a max-width for the items */
}

.pagination-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin: 2rem 0;
}

.pagination-buttons .pagination-button {
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--color-primary);
    color: var(--color-secondary);
    min-width: 100px;
}

@media screen and (max-width: 1024px) {
    .cards-container {
        padding: 1rem 1rem;
    }
    .cards-container .blogCard {
        margin-bottom: 60px;
    }

    .page-content.blog-page {
        padding: 4rem 1rem;
    }
}