.button-default {
    background-color: var(--color-blue);
    padding: 1.2rem 1.6rem;
    color: var(--color-secondary);
    border-radius: 0.8rem;
    cursor: pointer;
    font-size: 1rem;
    display: inline-block;
    width: 300px;
}
@media screen and (max-width: 1024px) {
    .button-default {
        width: 100%;
    }
}