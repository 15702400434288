
.home-header {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    max-height: 500px;
    overflow: hidden;
}

.home-header img {
    width: 100%;
    height: 100%;
}

.home-header div {
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem;
    gap: 0.1rem;
    background-color: var(--color-light-blue);
}

.home-header div .button-default {
    max-width: 300px;
    margin: auto;
    text-wrap: none;
}

.welcome {
    padding: 2rem 20rem;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.welcome div.red-box {
    display: block;
    background-color: var(--color-light-violet);
    position: relative;
}

.welcome div.pink-box {
    display: block;
    font-size: 0.9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: var(--color-pink);
    position: relative;
}

.welcome .pink-box::after {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--color-light-red);
    position: absolute;
    bottom: -1rem;
    left: 0;
}

.welcome .pink-box::before {
    content: "";
    display: block;
    height: 2px;
    width: 100%;
    background-color: var(--color-light-red);
    position: absolute;
    top: -1rem;
    left: 0;
}

.who-we-are {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-template-rows: 100%;
}

.who-we-are .why-us {
    background-color: var(--color-blue);
    color: var(--color-secondary);
    padding: 5rem;
}

.who-we-are .why-us h4 {
    margin-bottom: 1.2rem;
}

.who-we-are .why-us hr {
    height: 0.15rem;
    margin-top: 0.2rem;
    background-color: var(--color-secondary);
}

.who-we-are .services {
    padding: 5rem;
    background-color: var(--color-light-gray);
    color: var(--color-primary);
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 50% 50%;
    row-gap: 1rem;
    column-gap: 1rem;
}

.who-we-are .services .img-decoration {
    position: relative;
    width: 200px;
    height: 200px;
}

.section-content {
    padding: 4rem 15rem;
    text-align: center;
}

.section-content h3 {
    color: var(--color-blue);
    margin-bottom: 2rem;
    font-weight: bold;
    font-size: 2.5rem;
}

.team p {
    text-align: justify;
    width: 100%;
    margin: 2rem 0;
}

.team .members {
    display: grid;
    width: 100%;
    grid-template-rows: 50% 50%;
    grid-template-columns: 22% 22% 22%;
    justify-content: space-between;
    row-gap: 2rem;
}

.blog {
    background-color: var(--color-secondary);
}

.contact {
    background-color: var(--color-light-gray);
}

.blog .button-default {
    margin-top: 2rem;
}

.three-in-row {
    display: grid;
    grid-template-columns: 31% 31% 31%;
    grid-template-rows: 100%;
    margin-top: 2rem;
    justify-content: space-between;
}

.review-card {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    gap: 2rem;
    justify-content: center;
    align-items: center;

}

.review-card img {
    width: 100%;
    height: auto;
    object-fit: contain;
}

.review-card p {
    text-align: justify;
    font-size: 0.9rem;
}

.support-section {
    background-color: var(--color-secondary);
}

.support {
    display: grid;
    grid-template-columns: 40% 60%;
    grid-template-rows: 100%;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;
}

.support h1 {
    color: var(--color-blue);
    margin: auto;
}

.support img, .support svg {
    max-width: 100%;
    margin: auto;
}

/* MEDIA QUERIES (MEDIUM AND SMALL) */
@media screen and (max-width: 1024px) {
    .home-header {
        position: relative;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        max-height: none;
    }

    .home-header img {
        max-height: 250px;
    }

    .home-header div {
        padding: 2rem;
        text-align: center;
    }

    .welcome {
        position: relative;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
        padding: 3rem 2rem;
    }

    .who-we-are {
        position: relative;
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto;
    }

    .who-we-are .why-us {
        padding: 3rem;
    }

    .who-we-are .services {
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto auto;
        padding: 2rem;
    }

    .section-content {
        padding: 3rem;
    }

    .team .members {
        grid-template-rows: auto;
        grid-template-columns: 100%;
        justify-items: center;
    }

    .support {
        grid-template-rows: 50% 50%;
        grid-template-columns: 100%;
        gap: 1.5rem;
        margin-bottom: 60px;
    }

    .support img {
        max-height: 200px;
    }

    .three-in-row {
        display: grid;
        grid-template-columns: 100%;
        grid-template-rows: auto auto auto;
        margin-top: 2rem;
        justify-content: space-between;
    }
}