nav {
    height: auto;
    width: 100%;
    background-color: var(--color-secondary);
    display: grid;
    place-items: center;
    z-index: 99;
}

.nav-logo {
    height: 6rem;
}

.nav-toggle {
    display: none;
}

.nav-container {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.nav-links {
    margin-left: 3rem;
    display: flex;
    gap: 2.5rem;
    align-items: center;
}

.nav-links a {
    color: var(--color-primary);
    transition: var(--transition);
    cursor: pointer;
}

.nav-links li.highlight {
    border: 1px solid var(--color-blue);
    padding: 0.8rem 1.2rem;
    border-radius: 2rem;
    color: var(--color-blue);
}


.nav-links a:hover {
    color: var(--color-blue);
    font-weight: bold;
}

.nav-links a.nav-active {
    color: var(--color-light-red);
    font-weight: bold;
}

/* MEDIA QUERIES (MEDIUM AND SMALL) */
@media screen and (max-width: 1024px) {
    .nav-logo {
        height: 4rem;
    }
    .nav-container {
        justify-content: space-between;
    }
    
    .nav-toggle {
        display: inline-block;
        background-color: transparent;
        font-size: 1.8rem;
        cursor: pointer;
    }

    .nav-toggle svg {
        color: var(--color-ligth-grey);
    }

    .nav-links {
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        flex-direction: column;
        gap: 0;
        z-index: 10;
    }

    .nav-links.active {
        display: flex;
    }

    .nav-links li {
        height: 3rem;
        width: 100%;
        box-sizing: -2rem 2rem 4rem rgba(0,0,0,0.4);
    }

    .nav-links li.highlight {
        background-color: var(--color-blue);
        padding: 0;
        border-radius: 0;
    }

    .nav-links li a {
        background-color: var(--color-blue);
        color: var(--color-secondary);
        height: 100%;
        width: 100%;
        display: flex;
        padding: 1rem 4rem 1rem 1rem;
    }

    .nav-links a:hover {
        color: var(--color-light-red);
        font-weight: bold;
    }

    .nav-links li.highlight a {
        background-color: var(--color-blue);
        color: var(--color-secondary);
        height: 100%;
        width: 100%;
        display: flex;
        padding: 1rem 4rem 1rem 1rem;
        border-radius: 0;
    }
}