.member-card {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
}

.member-card img {
    width: 100%;
    margin: auto;
    text-align: center;
}

.member-card .member-btn {
    display: inline-flex;
    margin: auto;
    align-self: center;
    flex-direction: row;
}

.member-card .member-btn .linkedin {
    color: rgb(2, 112, 156);
    font-size: 1.3rem;
    margin-top: .2rem;
    margin-left: .8rem;
    position: relative;
    cursor: pointer;
}

.member-card .member-btn .linkedin::before {
    content: "";
    display: block;
    border-radius: 0.05rem;
    position: absolute;
    left: -0.5rem;
    top: 0rem;
    height: 1.8rem;
    width: 0.15rem;
    color: var(--color-blue);
    background-color: var(--color-blue);
}

@media screen and (max-width: 1024px) {
    .member-card img {
        width: 50%;
    }
}