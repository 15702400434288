.wa-icon {
    display: block;
    width: 80px;
    height: 80px;
    background-color: #32ba46;
    color: white;
    cursor: pointer;
    font-size: 55px;
    text-align: center;
    border-radius: 50%;
}