
.blogCard {
    display: block;
    position: relative;
    text-align: left;
}

.blogCard img {
    width: 100%;
    height: auto; /* maintain aspect ratio */
    object-fit: cover; /* or "contain" depending on your needs */
}

.blogCard .author {
    font-size: 0.8rem;
    padding-right: 0.3rem;
}

.blogCard .author a {
    color: var(--color-blue);
}

.blogCard .author a:hover {
    text-decoration: underline;
}

.blogCard .date {
    font-size: 0.8rem;
}

.blogCard h5 a {
    color: var(--color-blue);
    margin-bottom: 0.8rem;
}
.blogCard h5 a:hover {
    text-decoration: underline;
}

.blogCard p {
    font-size: 0.9rem;
    margin-bottom: 0.2rem;
}

@media screen and (max-width: 1024px) {
    .blogCard {
        padding-bottom: 20px;
        border-bottom: 1px solid var(--color-blue);
        margin-bottom: 50px;
    }

    .blogCard h5 {
        color: var(--color-blue);
        margin-bottom: 0.5rem;
        margin-top: 0.5rem;
        font-size: 1.5rem;
    }

    .blogCard img {
        width: 60%;
        margin: auto;
    }
}

