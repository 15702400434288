*, *::before, *::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --color-primary: #222;
  --color-primary-alpha-70: #222222aa;
  --color-secondary: #fff;
  --color-blue: #2b3780;
  --color-light-blue: #50c3c8;
  --color-light-red: #d36866;
  --color-light-violet: #ffddf1;
  --color-pink: #f2d1d6;
  --color-light-gray: #e8eaf3;

  --container-width-lg: 80%;
  --container-width-md: 90%;
  --container-width-sd: 100%;

  --transition: all 500ms ease;
}

body {
  color: var(--color-primary);
  margin: 0;
  line-height: 1.7;
  overflow-x: hidden;
  background-color: var(--color-secondary);
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5 {
  line-height: 1.2;
  font-weight: normal;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.8rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.2rem;
}

img {
  display: block;
  object-fit: cover;
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

.container.full {
  width: 100%;
  max-width: 1920px;
}

.align-r {
  text-align: right;
}

.box {
  padding: 1rem 0.8rem;
  border-radius: 0.8rem;
}

input, textarea {
  display: block;
  width: 100%;
  font-family: 'Roboto', sans-serif;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  padding: 0.9rem;
  font-size: 1.1rem;
  line-height: 1.6rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.7rem;
  margin: 1.2rem 0;
  webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

textarea {
  min-height: 200px;
}

.text-clickeable {
  cursor: pointer;
}

.text-clickeable:hover {
  text-decoration: underline;
  font-weight: bold;
}

.page-header {
  display: block;
  width: 100%;
}

.page-header-title {
  display: block;
  background-color: var(--color-light-gray);
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  padding: 2rem 0;
}

.page-header img {
  width: 100%;
  height: 400px;
}

.page-header div {
  display: flex;
  flex-direction: column;
  padding: 6rem;
  gap: 1.5rem;
  background-color: var(--color-light-blue);
}

.page-content {
  padding: 4rem 12rem;
  text-align: justify;
  font-size: 1.2rem;
}

@media screen and (max-width: 1280px) {
  .page-content {
    padding: 3rem 2rem;
  }
}